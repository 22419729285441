<script setup lang="ts">
/* eslint-disable */
import { getDescriptionItemFormUrl, getPageDescription } from "@/common/urls";
import { useUserStore } from "@/stores/user";
import { PageDescription } from "@/types/PageDescriptionListItem";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import { VueFinalModal } from 'vue-final-modal'
import { isDevelopment } from "@/common/env";

const title = 'Power BI Embedded Report';

const userStore = useUserStore();
const { isManager } = storeToRefs(userStore);

const description = ref<PageDescription | null>(null);

const editFromLink = computed(() => {
  return getDescriptionItemFormUrl(description.value?.id);
});

onMounted(async() => {
  // get parameters from url
  const urlParams = new URLSearchParams(window.location.search);
  await getPageDescription(urlParams.get("url") ?? "").then((res) => {
    if (res) {
      // Update links in the description content
      const updatedContent = updateLinksInDescription(res.Content);
      description.value = { ...res, Content: updatedContent };
    } else {
      description.value = { Id: 0, Content: "Unable to load description" };
    }
  });
});

const updateLinksInDescription = (htmlContent: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  
  doc.querySelectorAll("a[href^='/sites/'], img[src^='/sites/']").forEach(el => {
    const attr = el.tagName === "A" ? "href" : "src";
    el.setAttribute(attr, `https://abb.sharepoint.com${el.getAttribute(attr)}`);
  });
  
  return doc.body.innerHTML;
};


</script>

<template>
  <VueFinalModal class="desc-modal" content-class="desc-modal-content" overlay-transition="vfm-fade"
    content-transition="vfm-fade">
    <div class="header">
      <span>{{ title }}</span>
      <div v-if="isManager" class="btn">
        <a :href="editFromLink" target="_blank">
          <font-awesome-icon icon="fa-solid fa-edit" />
        </a>
      </div>
    </div>
    <div v-html="description?.Content" />
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #666;
}

.btn {
  color: #666;
  padding: 0px;
  margin: 0px;
  background: transparent;
  transition: 0.5s;

  &:hover {
    background-color: transparent;
    color: #111;
  }

  a {
    color: #666;
    transition: 0.5s;

    &:hover {
      color: #111;
    }
  }
}
</style>

<style>
.description-modal img {
  max-width: 100%;
  object-fit: contain;
}

.desc-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
}

.desc-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  min-width: 25%;
  max-width: 50%;
  overflow-y: auto;
  max-height: 90vh;
}

.desc-modal-content>*+* {
  margin: 0.5rem 0;
}

.desc-modal-content h1 {
  font-size: 1.375rem;
}

.desc-modal-content button {
  margin: 0.25rem 0 0 auto;
  padding: 0 8px;
  border: 1px solid;
  border-radius: 0.5rem;
}

.dark .desc-modal-content {
  background: #000;
}
</style>
